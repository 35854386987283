import { Button, Container, OldHeading } from '@gassan-ui'
import { cn } from '@lib/cn'
import { HomepageHeroItem } from '@lib/kontent/models/HomepageHeroItem'
import { m } from 'framer-motion'
import { FC } from 'react'

type SlideContentProps = {
  item: HomepageHeroItem
}

export const SlideContent: FC<SlideContentProps> = ({ item }) => {
  const isRolex = item.apply_brand_style.value[0]
    ? item.apply_brand_style.value[0].codename === 'rolex'
    : !!item.apply_rolex_style.value[0]
  const hideContentOnDesktop = item.apply_brand_style.value[0]
    ? item.apply_brand_style.value[0].codename === 'chanel'
    : false
  const style = item.style.value[0]?.codename || 'dark'
  const align = item.align.value[0]?.codename || 'left'

  return (
    <>
      {!hideContentOnDesktop && (
        <Container
          className={cn(
            'absolute bottom-4 left-1/2 z-10 hidden -translate-x-1/2',
            'md:bottom-24 lg:block xl:bottom-1/4',
          )}
        >
          {item.title.value && (
            <m.h2
              key={`${item.button_text.value}-title`}
              className={cn(
                'mx-auto whitespace-pre-line pb-4 text-center font-heading text-[1.75rem] leading-tight tracking-wide text-shade-800',
                'md:max-w-[70%] md:pb-8 md:text-[2.5rem]',
                'lg:max-w-[650px] lg:text-[3rem]',
                'xl:max-w-[750px] xl:text-[4rem]',
                'xxl:max-w-[830px] xxl:text-[4.5rem]',
                'data-[align=left]:m-0 data-[align=left]:justify-start data-[align=left]:text-left xl:data-[align=left]:m-0',
                'data-[slide-style=light]:text-white',
              )}
              data-align={align}
              data-slide-style={style}
              style={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.25, ease: 'easeInOut' }}
            >
              {item.title.value}
            </m.h2>
          )}
          <m.div
            key={`${item.button_text.value}-button`}
            className={cn('flex justify-center', 'data-[align=left]:justify-start')}
            data-align={align}
            style={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.75, duration: 0.25, ease: 'easeInOut' }}
          >
            <Button
              as="link"
              href={item.button_href.value}
              className="min-w-full sm:min-w-[auto]"
              variant={isRolex ? 'rolex' : style ? 'dark' : 'light'}
            >
              {item.button_text.value}
            </Button>
          </m.div>
        </Container>
      )}
      <m.div
        className="block lg:hidden"
        key={`mobile-${item.button_text.value}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.25, duration: 0.25, ease: 'easeInOut' }}
      >
        <Container
          className={cn('flex flex-col items-center bg-white py-6 text-center', 'lg:hidden')}
        >
          {item.title.value && (
            <OldHeading variant="hXl" mb={{ _: '1rem', medium: '2rem' }}>
              {item.title.value}
            </OldHeading>
          )}
          <Button
            as="link"
            href={item.button_href.value}
            className="min-w-full sm:min-w-[auto]"
            variant={
              !!item.apply_rolex_style.value[0]
                ? 'rolex'
                : item.style.value[0]?.codename || 'dark'
                ? 'dark'
                : 'light'
            }
          >
            {item.button_text.value}
          </Button>
        </Container>
      </m.div>
    </>
  )
}
